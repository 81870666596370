<template>
<div class="">
    <div class="container-fluid py-5" style="background-color: #FFFDEE">
        <div class="container ">
            <div class="row ">
                <div class="col-12 col-md-7 col-lg-8">
                    <h2 class="tittle">{{detail.article_title}}</h2>
                    <span class="tdate">{{detail.category}} | {{detail.day}} {{detail.month}} {{detail.year}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-8">
                    <div class="row">
                        <div class="col-12">
                            <!-- <img src="../assets/images/83cd55d7-39b0-4252-a41a-d895521d1d94.jpg" width="100%" alt=""> -->
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+detail.article_img" alt="" width="100%">
                        </div>
                    </div>
                
                    <div class="row">
                        <div class="col-12 pt-4">
                            <h6 class="mt-2 text-comm" v-html="detail.article_content"></h6>
                        </div>
                    </div>
                        
                </div>
                <!-- <div class="col-12 col-md-6 col-lg-1 ">
                </div> -->
                <div class="col-12 col-md-6 col-lg-4 mt-3 mt-lg-0 ps-0 ps-lg-2">
                    <div class="row">
                        <div class="col-12">
                            <!-- <h5 class="text-tittle">Lates News</h5>
                            <hr  style="border: 1px solid #A5814F;; width: 100%; opacity:100"> -->
                                 <div class="p-4" style="background-color : #2E2D2D" v-for="latesnew in lates" :key="latesnew.index" @click="HandleSubmit()">
                                    <div class="row">
                                        <div class="col-6">
                                            <router-link :to="{ name: 'detail_event_promo', params: { slug: latesnew.slug }}" class="nav-link px-0 mt-0">
                                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+latesnew.article_img" class="img-fluid" alt="" width="100%" >
                                            </router-link>
                                        </div>
                                        <div class="mt-0 col-6 align-self-center">
                                            <router-link :to="{ name: 'detail_event_promo', params: { slug: latesnew.slug }}" class="nav-link px-0 mt-0">
                                            <h6 class="text-title" >{{latesnew.article_title}}  </h6>
                                            <small class="text-date" >{{latesnew.category}} |  {{latesnew.day}} {{latesnew.month}} {{latesnew.year}}</small>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
     name: 'detailNews',
     data(){
            return{
                detail:[], 
                lates:[],
                eventcat:[],
                promocat:[],
            }
        },
    props:['slug'],
    mounted () {
    window.scrollTo(0, 0)
    },
    created(){
        this.$axios.get('https://adm-estusae.makutapro.id/api/detailarticle?slug=' + this.$route.params.slug)
        .then((response) => {
        this.detail = response.data.data[0] 
        // console.log(this.detail) 
        })
        this.$axios.get('https://adm-estusae.makutapro.id/api/latestarticle')
        .then((response) => {
        // console.log(response)
            this.lates = response.data
        })
        this.$axios.get('https://adm-estusae.makutapro.id/api/eventcategory')
        .then((response) => {
        // console.log(response)
            this.eventcat = response.data
        })
        this.$axios.get('https://adm-estusae.makutapro.id/api/promocategory')
        .then((response) => {
        // console.log(response)
            this.promocat = response.data
        })
        
    },
    methods: {
        HandleSubmit(){
             this.$axios.get('https://adm-estusae.makutapro.id/api/detailarticle?slug=' + this.$route.params.slug)
            .then((response) => {
            this.detail = response.data.data[0] 
            })
        }
    }
}
</script>
<style scoped>
.bg-card{
    background-color: #2E2D2D;
}

.tittle{
    font-family: Manuale;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #2E2D2D;
}
.tdate{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #2E2D2D;

}
.judul{
    font-family: Manuale;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.025em;
    color: #FFFFFF;
}
.tanggal{
    font-family: Montserrat;
    letter-spacing: -0.025em;
    color: #FFFFFF;
}

.text-comm{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.025em;
    color: #2E2D2D;
    line-height: 32px;
}

.tebal{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #2E2D2D;
    line-height: 32px;
}
.text-date{
    font-family: Montserrat;
    font-style: normal;
    font-size: 10px;
    color: #FFFFFF;

}
.text-title{
    font-family: Manuale;
    font-style: normal;
    letter-spacing: -0.025em;
    color: #FFFFFF;

}
</style>
