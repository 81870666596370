import { render, staticRenderFns } from "./DetailEvent.vue?vue&type=template&id=4042b008&scoped=true&"
import script from "./DetailEvent.vue?vue&type=script&lang=js&"
export * from "./DetailEvent.vue?vue&type=script&lang=js&"
import style0 from "./DetailEvent.vue?vue&type=style&index=0&id=4042b008&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4042b008",
  null
  
)

export default component.exports